import React from 'react'
import {Container, Row, Col} from 'reactstrap';

import Layout from '../components/layout'
import SEO from '../components/seo'
import TextbausteinImage from "../components/TextbausteinImage";

const Textfelder = (props) => (
    <Layout>
        <SEO title="Home" keywords={[`medoboard`, `Arztsoftware`, `intelligent`, `einfach`]}/>
        <Container>
            <Row className="carousel">
                <Col md="12">
                    <TextbausteinImage/>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <h2 className="featurette-heading">Adaptive Textfelder</h2>
                    <h4 className="text-muted">Optimieren sie ihren Diagnoseworkflow</h4>
                    <p className="lead">
                        Diagnosen sind - unabhängig vom Fachgebiet - äußerst komplex.
                    </p>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <hr className="featurette-divider"/>
        </Container>
    </Layout>
)

export default Textfelder;